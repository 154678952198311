const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];

function getLocalPreselection(pageType, defaults) {
  if (pageType === 'category') {
    if (defaults.termFromBreadcrumbs === 'Shop All') {
      return [];
    }

    if (defaults.termFromBreadcrumbs.startsWith('Shop All')) {
      const trimmedTermFromBreadcrumbs = defaults.termFromBreadcrumbs.replace('Shop All>', '');
      return [
        {
          field: 'category',
          term: trimmedTermFromBreadcrumbs,
          treeLevel: trimmedTermFromBreadcrumbs.split('>').length - 1,
        },
      ];
    }
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
    sort:
      window.document.querySelector('body.category') &&
      window.document.querySelector('.breadcrumbs .is-active .breadcrumb-label')?.innerText !== 'Shop All'
        ? 'is_featured:desc'
        : 'relevance',
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm-results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: 'body.category .container.catagory-space > .row, body.brand .container.sing-brand > .row',
      },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'is_featured:desc': 'Featured',
        'date_created:desc': 'Newest',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'Customer Review',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FacetPanel',
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { replace: '#cm-search-box-desktop', class: 'form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#cm-search-box-mobile',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: '#cm-home-ymm', class: 'container' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        selector: '#cm-garage',
        class: 'navUser-action',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: '.productView-details .productView-price' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: 'body.product #cm-fitment-table-tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: 'body.product #cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
