//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_8512,_3704,_2328,_484,_2296,_3116,_480,_8772;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_8512,_3704,_2328,_484,_2296,_3116,_480,_8772");
        }
      }catch (ex) {
        console.error(ex);
      }